.container {
  position: absolute;
  top: 30%;
  left: 10px;
  text-align: center;
}

.container h2 {
  font-size: 5rem;
}

.container a {
  font-size: 1.4rem;
}
